.nesProgress {
  @include compact-rounded-corners();

  width: 100%;
  height: 48px;
  margin: 4px;
  color: $base-color;
  background-color: $background-color;
  -webkit-appearance: none;
  appearance: none;

  @mixin progress-style($color) {
    &::-webkit-progress-bar {
      background-color: $background-color;
    }
    &::-webkit-progress-value {
      background-color: $color;
    }
    &::-moz-progress-bar {
      background-color: $color;
    }
    &::-ms-fill {
      background-color: $color;
      border: none;
    }
  }

  @include progress-style($base-color);

  &.isRounded {
    @include rounded-corners();
  }
  &.isPrimary {
    @include progress-style(map-get($primary-colors, "normal"));
  }

  &.isSuccess {
    @include progress-style(map-get($success-colors, "normal"));
  }

  &.isWarning {
    @include progress-style(map-get($warning-colors, "normal"));
  }

  &.isError {
    @include progress-style(map-get($error-colors, "normal"));
  }

  &.isPattern {
    &::-webkit-progress-value {
      background-color: $base-color;
      background-image: linear-gradient(
          45deg,
          $background-color 25%,
          transparent 25%,
          transparent 75%,
          $background-color 75%,
          $background-color
        ),
        linear-gradient(
          45deg,
          $background-color 25%,
          transparent 25%,
          transparent 75%,
          $background-color 75%,
          $background-color
        );
      background-position: 0 0, 10px 10px;
      background-size: 20px 20px;
    }

    &::-moz-progress-bar {
      background-color: $base-color;
      background-image: -moz-linear-gradient(
          45deg,
          $background-color 25%,
          transparent 25%,
          transparent 75%,
          $background-color 75%,
          $background-color
        ),
        -moz-linear-gradient(45deg, $background-color 25%, transparent 25%, transparent
              75%, $background-color 75%, $background-color);
      background-position: 0 0, 10px 10px;
      background-size: 20px 20px;
    }

    &::-ms-fill {
      background-color: $base-color;
      background-image: linear-gradient(
          45deg,
          $background-color 25%,
          transparent 25%,
          transparent 75%,
          $background-color 75%,
          $background-color
        ),
        linear-gradient(
          45deg,
          $background-color 25%,
          transparent 25%,
          transparent 75%,
          $background-color 75%,
          $background-color
        );
      background-position: 0 0, 10px 10px;
      background-size: 20px 20px;
      border: none;
    }
  }
}
