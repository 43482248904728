@import "./variables.module.scss";

.navContentContainer {
  max-width: 1300px;
  margin: auto;
  display: flex;
}

.home {
  font-size: 30px;
  line-height: 96%;
  letter-spacing: -0.02em;
  font-weight: 700;
  padding: 30px;
  &:hover {
    cursor: pointer;
  }
}

.rightSide {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.navItem {
  font-weight: 600;
  padding: 4px 10px;
  margin: 0 3px;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.yellowStrip {
  background-color: $mustard;
}

@media screen and (max-width: 800px) {
  .navItem {
    display: none;
  }
}
