@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap");
@import "../home/variables.module.scss";

.fullContainer {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 240px);
}

.contentContainer {
  margin: 20px 20px;
}

.header {
  text-align: left;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 0 0 20px 0;
  font-size: 30px;
}

@media screen and (max-width: $mobile) {
  .header {
    font-size: 24px;
  }
}

.subHeader {
  text-align: left;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-top: 25px;
}

.description {
  text-align: left;
  font-size: 16px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  margin: 10px 0;
  color: #4d4d4d;
}

.descriptionContact {
  text-align: left;
  font-size: 16px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  margin: 10px 0;
  color: #4d4d4d;
  margin-bottom: 80px;
}

.upgradeContainer {
  background-color: rgb(208, 240, 253);
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  color: rgb(77, 77, 77);
  font-family: "Lexend Deca", sans-serif;
  max-width: 450px;
  line-height: 1.5;
}

.upgradeHeader {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: rgb(77, 77, 77);
}

.benefit {
  margin: 5px;
  font-size: 14px;
}

.upgradeButton {
  background: #272635;
  color: #ffffff;
  padding: 8px 20px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 15px;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.signOutButton {
  height: 34px;
  background-color: #272635;
  color: white;
  font-family: "Lexend Deca", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  max-width: 150px;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  margin-top: 20px;
  &:hover {
    opacity: 0.85;
  }
}
