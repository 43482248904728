@import url("https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import "../home/variables.module.scss";

// this becomes global so you dont need it

// ::-webkit-calendar-picker-indicator {
//   background: $primary;
//   border-radius: 3px;
//   padding-top: 5px;
//   padding-bottom: 4px;
//   color: white;
//   &:hover {
//     cursor: pointer;
//   }
// }

.weekInput {
  font-family: Lexend Deca, serif;
  font-size: 12px;
  margin: 3px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.46em 0.7em 0.4em 0.3em;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: #888;
  }
}

.weekInputiOS {
  font-family: Lexend Deca, serif;
  font-size: 14px;
  margin: 8px;
  color: #444;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
}

// start bday entry
.enterDateContainer {
  max-width: 700px;
  margin: 80px auto;
  text-align: center;
  min-height: calc(100vh - 350px);
}

.holidayContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
}

.holiday{
  margin: 5px;
  padding: 10px;
  background-color: #266dd3;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  transition: .2s;
  &:hover{
    cursor: pointer;
    opacity: .8;
  }
}

.entryHeader {
  font-size: 35px;
  font-family: "Roboto Slab", serif;
  margin: auto;
}

.entryHelpText {
  padding: 15px;
  font-size: 16px;
  font-family: "Lexend Deca", serif;
  margin: auto;
  text-transform: uppercase;
  opacity: 0.7;
}

.homeHelpText {
  padding: 5px;
  font-size: 12px;
  font-family: "Lexend Deca", serif;
  color: #565b73;
  opacity: 0.8;
  max-width: 450px;
  margin: 10px auto;
}

.header {
  font-size: 30px;
  font-family: "Roboto Slab", serif;
  margin: auto;
}

.subHeader {
  font-size: 20px;
  font-family: "Roboto Slab", serif;
  margin: 5px auto;
}

.weeksNumer {
  font-size: 90px;
  font-family: "Roboto Slab", serif;
  margin: 10px auto;
  font-weight: 500;
}

.enterButton {
  font-size: 12px;
  font-family: "Lexend Deca", serif;
  display: inline-block;
  padding: 7px 30px;
  font-weight: 600;
  background: $primary;
  text-align: center;
  color: white;
  border-radius: 5px;

  &:hover {
    background: $primary-hover;
    cursor: pointer;
    color: hsla(0, 0%, 100%, 0.95);
  }
}

.copyButton {
  font-size: 12px;
  font-family: "Lexend Deca", serif;
  display: inline-block;
  padding: 7px 15px;
  font-weight: 600;
  background: $primary;
  text-align: center;
  color: white;
  border-radius: 5px;

  &:hover {
    background: $primary-hover;
    cursor: pointer;
    color: hsla(0, 0%, 100%, 0.95);
  }
}

.helpText {
  padding: 5px;
  font-size: 14px;
  font-family: "Lexend Deca", serif;
  color: #565b73;
  margin-top: 15px;
}

.copyLinkInput {
  font-family: Lexend Deca, serif;
  max-width: 450px;
  display: inline-block;
  font-size: 12px;
  margin: 3px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.46em 0.7em 0.45em 0.3em;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

.weeksFromTodayLink{
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}

.weeksTitle {
  font-size: 60px;
  font-family: "Roboto Slab", serif;
  margin: 10px auto;
  font-weight: 500;
}

.weeksAwayBlock{
  margin-top: 30px;
}
