#mesh {

  background-color:#a099ff;
  background-image:
  radial-gradient(at 92% 16%, hsla(259,65%,69%,1) 0px, transparent 50%),
  radial-gradient(at 38% 78%, hsla(233,62%,64%,1) 0px, transparent 50%),
  radial-gradient(at 93% 87%, hsla(343,95%,64%,1) 0px, transparent 50%),
  radial-gradient(at 59% 57%, hsla(235,71%,77%,1) 0px, transparent 50%),
  radial-gradient(at 45% 79%, hsla(345,91%,67%,1) 0px, transparent 50%),
  radial-gradient(at 42% 54%, hsla(146,68%,75%,1) 0px, transparent 50%),
  radial-gradient(at 45% 34%, hsla(219,95%,68%,1) 0px, transparent 50%);
}