@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import "./variables.module.scss";

.splashContainer {
  width: 100%;
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  padding-bottom: 100px;
}

.splashHeadingContainer {
  margin: 0 40px;
}

.splashHeader {
  width: fit-content;
  font-size: 70px;
  line-height: 96%;
  font-weight: 700;
  padding: 5px;
  text-align: center;
  color: #f2f2f2;
  margin: auto;
  margin-bottom: 24px;
  padding-top: 80px;
  max-width: 650px;
}

.splashSubHeader {
  width: fit-content;
  font-size: 24px;
  line-height: 96%;
  font-weight: 600;
  padding: 5px;
  text-align: center;
  color: #f2f2f2;
  margin: auto;
  max-width: 600px;
}

.splashSubHeaderStale {
  width: fit-content;
  font-size: 24px;
  line-height: 96%;
  font-weight: 600;
  padding: 5px;
  text-align: center;
  color: #f04444;
  margin: auto;
  max-width: 600px;
}

.loadingFounder {
  background: #fff;
  width: 300px;
  height: 300px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 25px 25px 25px;
  margin-bottom: 40px;
  margin-top: 100px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.founderContainer {
  background: #fff;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 25px 25px 25px;
  margin-bottom: 40px;
  margin-top: 100px;
  border-radius: 15px;
  max-width: 300px;
  min-width: 250px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.founderTopRow {
  display: flex;
  align-items: center;
}

.founderNameAndHandle {
  margin: auto;
  margin-top: -60px;
}

.founderName {
  font-size: 33px;
  font-weight: 800;
  text-align: center;
}

.founderHandle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(100, 100, 100);
  text-align: center;
}

.whiteRing {
  background: white;
  transform: translateY(-50%);
  width: fit-content;
  margin: auto;
  border-radius: 100%;
}

.founderImageContainer {
  max-width: 125px;
  margin: auto;
  padding: 5px;
}

.founderImage {
  width: 100%;
  border-radius: 100%;
}

.founderBio {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.seeAnotherContainer {
  margin-top: 40px;
}

.seeAnother {
  background-color: rgb(37, 40, 61);
  color: #f2f2f2;
  width: fit-content;
  margin: auto;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: 0.1s;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.reloadIcon {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-right: 4px;
}

.buySection {
  margin: 0 20px;
}

.manifestoSection {
  margin: 0 20px;
}

.buyHeader {
  font-size: 60px;
  line-height: 96%;
  font-weight: 700;
  padding: 5px;
  text-align: center;
  color: rgb(37, 40, 61);
  margin: auto;
  margin-bottom: 30px;
  padding-top: 80px;
}

.buyDescription {
  padding: 5px;
  // text-align: center;
  color: rgb(37, 40, 61);
  margin: auto;
  margin-bottom: 30px;
  max-width: 800px;
  line-height: 1.5;
}

.spreadContainer {
  margin: auto;
  margin-bottom: 30px;
  width: fit-content;
}

.speadsheetImage {
  width: 100%;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 800px;
  margin: auto;
}

.buyButton {
  background-color: rgb(37, 40, 61);
  color: #f2f2f2;
  width: fit-content;
  margin: auto;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: 0.1s;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.manifestoSection {
  margin-top: 40px;
}

.tweetMetaContainer {
  max-width: 1000px;
  margin: auto;
}

.tweetContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 120px;
}

.whoAreWeSection {
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  padding-bottom: 100px;
}

.whoAreWeHeader {
  font-size: 60px;
  line-height: 96%;
  font-weight: 700;
  padding: 5px;
  text-align: center;
  color: #fff;
  margin: auto;
  margin-bottom: 30px;
  padding-top: 80px;
}

.whoAreWeDesc {
  padding: 5px;
  // text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: auto;
  margin-bottom: 30px;
  max-width: 800px;
  line-height: 1.5;
}

.whoAreWeDesc a {
  color: #fff;
  text-decoration: underline;
}

.otherProjects {
  background-color: rgb(37, 40, 61);
  color: #f2f2f2;
  width: fit-content;
  margin: auto;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: 0.1s;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

@media screen and (max-width: 800px) {
  .splashHeadingContainer {
    margin: 0 20px;
  }
  .splashHeader {
    font-size: 40px;
    padding-top: 50px;
    margin-bottom: 15px;
  }

  .splashSubHeader {
    font-size: 18px;
  }

  .founderContainer {
    max-width: 225px;
    margin-left: auto;
    margin-right: auto;
  }

  .buyHeader {
    font-size: 40px;
  }

  .tweetContainer {
    display: block;
    max-width: 300px;
    margin: auto;
  }

  .whoAreWeHeader {
    font-size: 40px;
    margin: 0 20px 20px 20px;
  }

  .whoAreWeDesc {
    font-size: 16px;
    margin: 0 20px;
  }
}
