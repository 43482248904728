@import "../home/variables.module.scss";
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;900&display=swap");

.inputHolder {
  display: block;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  max-width: 501px;
  width: 256px;
  border-radius: 4px;
  background: white;
  margin: 15px 0;
}
.inputHolder:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: rgb(66, 71, 112);
}
.nameInput {
  display: block;
  max-width: 500px;
  width: 228px;
  padding: 10px 14px;
  font-size: 14px;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.nameInput::placeholder {
  color: #aab7c4;
}

.nameInput:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: rgb(66, 71, 112);
}

.payButton {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.025em;
  background-color: $primary;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-family: "Heebo", sans-serif;
  margin-right: 32px;
}

.payButton:hover {
  color: #fff;
  cursor: pointer;
  background-color: $primary-hover;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.paymentContainer {
  font-family: "Heebo", sans-serif;
  max-width: 512px;
  margin: 20px auto;
  padding: 10px 40px;
  color: rgb(77, 77, 77);
}

.header {
  font-size: 50px;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  margin-top: 30px;
}
.subHeader {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 32px;
}

.lineItemContainer {
  display: flex;
  font-weight: 900;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
}

.how {
  padding: 20px;
  background-color: #ffeab6;
  border-radius: 5px;
  color: #4d4d4d;
  font-weight: 400;
}

.howHeader {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}

.total {
  margin-top: 15px;
  padding-right: 50px;
  font-size: 25px;
  font-weight: 600;
}

.paymentLabel {
  font-size: 14px;
  font-weight: 600;
}
.checkoutForm {
  margin: 8px 0;
}

.buttonRow {
  display: flex;
  align-items: center;
}

.cancel {
  transition: 0.2s;
  font-size: 14px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.disclaimer {
  font-size: 12px;
  margin-top: 15px;
}

.errorText {
  margin: 10px 0;
  font-weight: 600;
  color: #cc0000;
  font-size: 20px;
}

.emptySpace {
  height: 100px;
}
