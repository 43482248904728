@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;900&display=swap");
@import "../home/variables.module.scss";

a {
  text-decoration: none;
}
.navbarContainer {
  display: flex;
  height: 45px;
  line-height: 45px;
  padding: 10px 20px;
  vertical-align: middle;
}
.homeLogo {
  color: #3a3335;
  font-size: 30px;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  transition: 0.2s;
}
.homeLogo:hover {
  color: #f7c548;
  cursor: pointer;
}
.navButtonsContainer {
  font-size: 14px;
  display: inline-block;
  padding-left: 40px;
  line-height: 45px;
  color: rgba(13, 17, 43, 0.8);
  font-family: "Lexend Deca", sans-serif;
}

.signInButton {
  font-size: 13px;
  margin-left: auto;
  text-align: center;
  color: hsla(0, 0%, 100%, 0.85);
  border-radius: 4px;
  // background: $primary;
  font-family: "Lexend Deca", sans-serif;
  padding: 0 20px;
  text-decoration: none;
}
.signInButton:hover {
  // background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.8);
}

.dropdown {
  line-height: 50px;
  position: relative;
  display: inline-block;
}

.navItem {
  transition: 0.1s;
  &:hover {
    color: $primary;
    cursor: pointer;
    display: block;
  }
}

.dropdownContent {
  transition: 0.1s;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 16px;
  z-index: 1;
  border-radius: 5px;
}

.dropdownContent a {
  width: 100%;
  &:hover {
    color: $primary;
  }
}

.dropdownItem {
  transition: 0.1s;
  color: rgba(13, 17, 43, 0.8);
  &:hover {
    color: $primary;
  }
}

.dropdown:hover .dropdownContent {
  display: block;
}

.profPic {
  height: 45px;
  border-radius: 100%;
}

@media screen and (max-width: $mobile) {
  .navItem {
    display: none;
  }
}
