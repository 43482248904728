@import "./variables.module.scss";

%rounded-corner-defaults {
  border-style: solid;
  border-width: $border-size;
}

@mixin border-image($color) {
  border-image-source: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="8" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M3 1 h1 v1 h-1 z M4 1 h1 v1 h-1 z M2 2 h1 v1 h-1 z M5 2 h1 v1 h-1 z M1 3 h1 v1 h-1 z M6 3 h1 v1 h-1 z M1 4 h1 v1 h-1 z M6 4 h1 v1 h-1 z M2 5 h1 v1 h-1 z M5 5 h1 v1 h-1 z M3 6 h1 v1 h-1 z M4 6 h1 v1 h-1 z" fill="rgb(#{red($color)},#{green($color)},#{blue($color)})" /></svg>');
}

@mixin compact-border-image($color) {
  border-image-source: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="5" height="5" xmlns="http://www.w3.org/2000/svg"><path d="M2 1 h1 v1 h-1 z M1 2 h1 v1 h-1 z M3 2 h1 v1 h-1 z M2 3 h1 v1 h-1 z" fill="rgb(#{red($color)},#{green($color)},#{blue($color)})" /></svg>');
}

@mixin border-image-repeat() {
  border-image-repeat: stretch;

  // for chrome
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    border-image-repeat: space;
  }

  // for firefox
  @supports (-moz-appearance: meterbar) {
    border-image-repeat: stretch;
  }
}

@mixin rounded-corners($isDark: false) {
  @extend %rounded-corner-defaults;

  border-image-slice: 3;
  border-image-width: 3;

  @include border-image-repeat();

  @if $isDark {
    @include border-image($color-white);

    border-image-outset: 0;
  } @else {
    @include border-image($color-black);

    border-image-outset: 2;
  }
}

@mixin compact-rounded-corners($isDark: false) {
  @extend %rounded-corner-defaults;

  border-image-slice: 2;
  border-image-width: 2;

  @include border-image-repeat();

  @if $isDark {
    @include compact-border-image($color-white);

    border-image-outset: 0;
  } @else {
    @include compact-border-image($color-black);

    border-image-outset: 2;
  }
}
