.header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}

.description {
  font-size: 14px;
  margin-bottom: 20px;
  color: rgb(97, 96, 96);
}

.formLabel {
  font-weight: 600;
}

.inputHolder {
  display: block;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  max-width: 501px;
  width: 256px;
  border-radius: 4px;
  background: white;
  margin: 5px 0 25px 0;
}
.inputHolder:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: rgb(66, 71, 112);
}
.nameInput {
  display: block;
  max-width: 500px;
  width: 228px;
  padding: 10px 14px;
  font-size: 14px;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  -webkit-transition: all 150ms ease;
  transition: all 250ms ease;
}

.nameInput::placeholder {
  color: #aab7c4;
}

.nameInput:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 6px 8px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  color: rgb(66, 71, 112);
}

.confirmOrder {
  font-family: "Poppins", sans-serif;
  padding: 8px 15px;
  background: #007aff;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: fit-content;
  border-radius: 5px;
  transition: 0.2s;
  border: none;
  &:hover {
    transform: translateY(-1px);
    cursor: pointer;
    opacity: 0.9;
  }
}
