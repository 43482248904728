@import "../home/variables.module.scss";

.pageContainer {
  margin: 0 40px;
}


.contentContainer {
  max-width: 1200px;
  margin: 50px auto;
}

.subTitle {
  font-size: 22px;
  font-weight: 700;
  color: $primary;
}

.title {
  font-size: 34px;
  font-weight: 700;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
}

.toolsGrid{
  display: flex;
  margin-bottom: 80px;
}

.toolCard{
  max-width: 300px;
  margin-right: 40px;
  color: #666;
  &:hover{
    cursor: pointer;
    opacity: .8;
  }
  color: #000;
}

.toolImageCont{

}

.toolsImage{
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.toolTitle{
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.toolDesc{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.experimentsGrid{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1%;
  margin-bottom: 80px;

}

.experimentsCard{
  // max-width: 500px;
  width: 29%;
  margin: 0 2%;
  color: #666;
  &:hover{
    cursor: pointer;
    opacity: .8;
  }
  color: #000;
}

.experimentsImageCont{

}

.experimentsImage{
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.experimentsTitle{
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.experimentsDesc{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.topRow {
  margin-top: 40px;
  display: flex;
  line-height: 1.5;
  font-size: 16px;
}

.imgDiv {
  max-width: 400px;
  min-width: 300px;
}

.aboutImage {
  width: 100%;
  border-radius: 5px;
}

.empireStateDesc {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #666;
}

.aboutTopText {
  margin-left: 15px;
}

.paraTwo {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 16px;
}

.moreAboutAuthor {
  margin-top: 50px;
}

.moreAboutAuthorContent {
  margin-top: 20px;
}

.fullRowText {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 16px;
}

.familyPhotoDiv {
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
}

@media screen and (max-width: $mobile) {
  .pageContainer {
    margin: 0 20px;
  }

  .topRow {
    display: block;
    font-size: 15px;
  }

  .imgDiv {
    margin: auto;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .aboutTopText {
    margin-top: 5px;
    margin-left: 0px;
  }
}
