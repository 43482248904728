.overlayBackground {
  background: black;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.overlayContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 700px;
  max-height: 90%;
  padding: 20px;
  box-sizing: border-box;
  background: white;
  z-index: 3;
  overflow-y: scroll;
  border-radius: 5px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cancel {
  position: fixed;
  top: 20px;
  right: 20px;
  color: #888;
  font-size: 28px;
  transition: 0.2s;
  margin-top: -10px;
}

.cancel:hover {
  opacity: 0.7;
  cursor: pointer;
}

.overlayContent {
  height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}
