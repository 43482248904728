@import url("https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import "./home/variables.module.scss";

.missing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  min-height: 80vh;
  font-size: 40px;
  font-weight: 600;
  font-family: "Lexend Deca";
  margin: 40px;
}

.header {
  width: 100%;
  transform: translatey(0px);
  animation: floatText 6s ease-in-out infinite;
  font-size: 80px;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes floatText {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.imageContainer {
  height: 30vh;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.bigButton {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  font-family: "Lexend Deca", sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  text-align: center;
  padding: 20px 26px;
  background: $primary;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin: 40px 0;
  font-size: 19px;
  padding: 20px;
  width: 150px;
}
.bigButton:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35);
}
.bigButton:active {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35),
    inset 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}
