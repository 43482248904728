@import "../home/variables.module.scss";

.thankYouContainer {
  font-family: "Lexend Deca";
  max-width: 512px;
  margin: 20px auto;
  padding: 10px 40px;
  color: rgb(77, 77, 77);
}

.header {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 32px;
  text-align: center;
}

.subHeader {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 32px;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.natureImage {
  max-height: 60vh;
  max-width: 90%;
}

.bigButton {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  font-family: "Lexend Deca", sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  text-align: center;
  padding: 20px 26px;
  min-width: 210px;
  background: $primary;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  width: 250px;
  margin: 40px auto;
  font-size: 19px;
}
.bigButton:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35);
}
.bigButton:active {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35),
    inset 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 800px) {
  .bigButton {
    padding: auto;
    width: 150px;
  }
}

.note {
  font-family: "Lexend Deca";
  max-width: 600px;
  margin: 20px auto 50px auto;
  padding: 10px 40px;
  color: rgb(77, 77, 77);
}
