@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");

.footerContainer {
  font-family: "Lexend Deca", sans-serif;
  text-align: center;
  padding: 50px 0px 60px 0px;
  background: #0d112b;
  color: #565b73;
}
.copyright {
  clear: both;
}
@media screen and (max-width: 800px) {
  .promoCopy {
    display: none;
  }
}
