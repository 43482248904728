@import url("https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;900&display=swap");
@import "./variables.module.scss";

.main {
  display: block;
  background-color: #f9f9f9;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.splashy {
  padding: 70px 0;
}

.text {
  margin: 0 auto;
  padding: 0 90px;
}

@media screen and (max-width: 800px) {
  .text {
    padding: 0 30px;
  }
}

.lead {
  color: $primary;
  vertical-align: middle;
  font-size: 12px;
  font-family: "Lexend Deca", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.bigButton {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  font-family: "Lexend Deca", sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  text-align: center;
  padding: 20px 26px;
  min-width: 210px;
  background: $primary;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  width: 300px;
  margin: 40px auto 20px auto;
  font-size: 19px;
}

.bigButton:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35);
}

.bigButton:active {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35),
    inset 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 800px) {
  .bigButton {
    padding: auto;
    width: 150px;
  }
}

.orLink {
  padding-bottom: 25px;
  font-size: 14px;
  font-family: "Lexend Deca", serif;
  color: #565b73;

  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

.bigButtonPoke {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  font-family: "Lexend Deca", sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  text-align: center;
  padding: 20px 26px;
  min-width: 210px;
  background: $primary;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  width: 300px;
  margin: 40px auto;
  font-size: 19px;
}

.bigButtonPoke:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35);
}

.bigButtonPoke:active {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35),
    inset 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 800px) {
  .bigButtonPoke {
    padding: auto;
    width: 150px;
  }
}

.splashHeader {
  font-family: "Lexend Deca", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 800px) {
  .splashHeader {
    font-size: 35px;
  }
}

.splashImage {
  border-radius: 5px;
  width: 80%;
  margin: auto;
}

.customerContainer {
  text-align: center;
  margin-top: 40px;
}

.divider {
  width: 75vw;
  margin: 30px auto;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  opacity: 0.7;
  height: 1px;
}

.pokemonContainer {
  display: flex;
  text-align: center;
  background-image: url("https://i.imgur.com/TqumijV.jpg");
  height: 450px;
}

.somethingNewContainer {
  display: flex;
  text-align: center;
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  height: 450px;
}

.dieContainer {
  display: flex;
  text-align: center;
  background-image: url("https://i.imgur.com/YU0bGS7.png");
  height: 450px;
}

.sectionTitle {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 600;
  padding: 20px 20px 0 20px;
}

.sectionDescription {
  font-family: "Lexend Deca", sans-serif;
  font-size: 18px;
  margin: 20px;
}

.copyContainer {
  margin: 40px;
  width: 50vw;
  background: rgba(255, 255, 255, 0.96);
  margin: auto;
  border-width: 1px;
  border-color: darkgray;
  border-style: solid;
  padding: 15px;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .copyContainer {
    margin: 50px 20px;
    width: 100%;
  }
}

.noDec {
  text-decoration: none;
}

.betterMeContainer {
  text-align: center;
  margin: 80px auto;
  max-width: 700px;
}

.sectionImage {
  width: 40vw;
}

@media screen and (max-width: 800px) {
  .sectionImage {
    width: 70vw;
  }
}

.sectionImageWide {
  margin-top: 30px;
  width: 100%;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 800px) {
  .sectionImageWide {
    // width: 70vw;
  }
}

.blobImage {
  width: 60vw;
}

@media screen and (max-width: 800px) {
  .blobImage {
    width: 90vw;
  }
}

.customerHeader {
  color: $primary;
  vertical-align: middle;
  font-size: 12px;
  font-family: "Lexend Deca", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.customers {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.customers {
  font-size: 40px;
  font-weight: 500;
}

.block {
  width: 150px;
  height: 800px;
  background: $primary;
}

.overlayBackGround {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  opacity: 0.4;
}

.modalContainer {
  z-index: 1;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  padding: 30px 70px;
  background: white;
  border-radius: 5px;
}

@media screen and (max-width: 800px) {
  .modalContainer {
    width: 80%;
    padding: 20px;
    top: 10%;
  }
}

.modalHeader {
  text-align: center;
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}

.modalDescription {
  font-family: "Lexend Deca", sans-serif;
  padding-top: 20px;
  font-size: 14px;
}

.emailAddressLabel {
  font-family: "Heebo", sans-serif;
  padding-top: 24px;
  font-weight: 600;
  font-size: 14px;
}

.emailInput {
  width: 100%;
  max-width: 300px;
  height: 20px;
}

.buyPass {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  font-family: "Lexend Deca", sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  text-align: center;
  padding: 20px 26px;
  min-width: 150px;
  background: $primary;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  width: 300px;

  margin: 40px auto;
  font-size: 19px;
}

@media screen and (max-width: 800px) {
  .buyPass {
    width: 50%;
  }
}

.buyPass:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
}

.modalDone {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: hsla(0, 0%, 100%, 0.85);
  border-radius: 4px;
  background: $primary;
  font-family: "Heebo", sans-serif;
  padding: 0 20px;
  width: 75px;
  height: 30px;
  line-height: 30px;
  margin: 10px 0 20px 0;
}

.modalDone:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
}

.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
}

.modalClose:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .splashImage {
    width: 95%;
  }
}

.bottomSection {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 200px;
}
