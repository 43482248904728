@import url("https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import "../home/variables.module.scss";

$square: 1.32%;
$square-margin: 0.3%;
.productHunt {
  position: absolute;
  right: 20px;
  top: 70px;
}
// start bday entry
.enterBirthday {
  max-width: 600px;
  margin: 20vh auto;
  text-align: center;
  min-height: calc(100vh - 500px);
}
.entryHeader {
  font-size: 35px;
  font-family: "Roboto Slab", serif;
  margin: auto;
}
.entryHelpText {
  padding: 15px;
  font-size: 16px;
  font-family: "Lexend Deca", serif;
  margin: auto;
  text-transform: uppercase;
  opacity: 0.7;
}
.yearEnter {
  margin: 5px;
  font-family: "Lexend Deca", serif;
}
.yearEnter {
  max-width: 150px;
  display: inline-block;
  font-size: 12px;
  margin: 3px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.yearEnter::-ms-expand {
  display: none;
}
.yearEnter:hover {
  border-color: #888;
}
.yearEnter:focus {
  border-color: #aaa;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.yearEnter option {
}
.enterButton {
  font-size: 12px;
  font-family: "Lexend Deca", serif;
  display: inline-block;
  padding: 7px 30px;
  font-weight: 600;
  background: $primary;
  text-align: center;
  color: white;
  border-radius: 5px;

  &:hover {
    background: $primary-hover;
    cursor: pointer;
    color: hsla(0, 0%, 100%, 0.95);
  }
}

.orLink {
  padding: 5px;
  font-size: 14px;
  font-family: "Lexend Deca", serif;
  color: #565b73;

  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

.folllowLink {
  padding: 5px;
  font-size: 18px;
  font-family: "Lexend Deca", serif;
  color: $primary;
  margin-top: 70px;
  width: fit-content;
  margin: 70px auto;
  &:hover {
    color: $primary-hover;
    opacity: 0.8;
    cursor: pointer;
  }
}

.followFlex {
  display: flex;
  align-items: center;
}

.twitterIcon {
  margin-left: 10px;
}
// end bday entry
