@import url("https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import "../../variables.module.scss";
.contentContainer {
  display: flex;
  border-radius: 5px;
}
.heroImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  height: 100%;
  width: 50%;
  border-radius: 5px;
}
.heroImage {
  max-width: 100%;
  max-height: 300px;
}
.heroText {
  margin-top: 40px;
  width: 50%;
}
.heroName {
  font-size: 25px;
  font-family: "Roboto Slab";
  font-weight: 600;
  margin-bottom: 5px;
}
.heroAge {
  font-size: 14px;
  font-family: "Lexend Deca";
}
.heroAccomplishmentLabel {
  font-family: "Roboto Slab";
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}
.heroDesc {
  font-size: 14px;
  font-family: "Lexend Deca";
  margin-bottom: 15px;
}
