@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap");
@import "../home/variables.module.scss";

.fullContainer {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 240px);
}

.contentContainer {
  margin: 20px;
  min-height: 70vh;
}

.descriptionText {
  opacity: 0.8;
  font-size: 14px;
  margin: 10px 0;
  color: black;
}

@media screen and (max-width: $mobile) {
  .descriptionText {
    font-size: 12px;
  }
}

.emptyText {
  text-align: center;
  font-size: 24px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 500;
  padding: 10px 40px;
  margin-bottom: 15px;
}

@media screen and (max-width: $mobile) {
  .emptyText {
    font-size: 18px;
    padding: 10px 20px;
  }
}

.emptyImageContainer {
  display: flex;
  margin-bottom: 25px;
}

.emptyImage {
  max-width: 300px;
  margin: auto;
}

@media screen and (max-width: $mobile) {
  .emptyImage {
    max-width: 240px;
  }
}

.linkInput {
  padding: 4px 4px;
  min-width: 200px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.header {
  font-size: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: $mobile) {
  .header {
    font-size: 24px;
  }
}

.proBadge {
  background: #f7c548;
  color: white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 18px;
}

@media screen and (max-width: $mobile) {
  .proBadge {
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 8px;
  }
}

.submitButton {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  font-family: "Lexend Deca", sans-serif;
  text-align: center;
  background: #f7c548;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin: 0px 0;
  font-size: 14px;
  padding: 5px;
  width: 100px;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transform: translateY(-1px);
  }
}

.trackerContainer {
  background: #efefef;
  padding: 15px 15px 0.1px 15px;
  border-radius: 5px;
  box-sizing: border-box;
}

.trackerRow {
  box-sizing: border-box;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  transition: 0.2s;
  &:hover {
    background: white;
    cursor: pointer;
  }
}

.postTitle {
  font-size: 18px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: $mobile) {
  .postTitle {
    font-size: 16px;
  }
}

.trashHolder {
  margin-left: auto;
  display: none;
  transition: 0.2s;
}

.trashIcon {
  font-size: 18px;
  opacity: 0.9;
  &:hover {
    opacity: 0.7;
  }
}

.trackerRow:hover > .trashHolder {
  display: inline;
  color: #272635;
}

.addNewParent {
  margin-top: 30px;
}

.addNewContainer {
  display: flex;
}

.upgradeButton {
  background: #272635;
  color: #ffffff;
  padding: 8px 20px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 15px;
  transition: 0.2s;
  font-family: "Lexend Deca", sans-serif;
  line-height: 1.5;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

// all row colors below

.rowIcon0 {
  height: 30px;
  width: 30px;
  margin-right: 7px;
  border-radius: 5px;
  background: #2d7ff9;
  flex-shrink: 0;
}

.rowIcon1 {
  height: 30px;
  width: 30px;
  margin-right: 7px;
  border-radius: 5px;
  background: #20c933;
  flex-shrink: 0;
}

.rowIcon2 {
  height: 30px;
  width: 30px;
  margin-right: 7px;
  border-radius: 5px;
  background: #f82b60;
  flex-shrink: 0;
}

.rowIcon3 {
  height: 30px;
  width: 30px;
  margin-right: 7px;
  border-radius: 5px;
  background: #8b46ff;
  flex-shrink: 0;
}

.rowIcon4 {
  height: 30px;
  width: 30px;
  margin-right: 7px;
  border-radius: 5px;
  background: #20d9d2;
  flex-shrink: 0;
}

.rowIcon5 {
  height: 30px;
  width: 30px;
  margin-right: 7px;
  border-radius: 5px;
  background: #f82b60;
  flex-shrink: 0;
}

// loading
.loadingHolder {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.skChase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: skChase 2.5s infinite linear both;
}

.skChaseDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: skChaseDot 2s infinite ease-in-out both;
}

.skChaseDot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #f7c548;
  border-radius: 100%;
  animation: skChaseDotBefore 2s infinite ease-in-out both;
}

.skChaseDot:nth-child(1) {
  animation-delay: -1.1s;
}
.skChaseDot:nth-child(2) {
  animation-delay: -1s;
}
.skChaseDot:nth-child(3) {
  animation-delay: -0.9s;
}
.skChaseDot:nth-child(4) {
  animation-delay: -0.8s;
}
.skChaseDot:nth-child(5) {
  animation-delay: -0.7s;
}
.skChaseDot:nth-child(6) {
  animation-delay: -0.6s;
}
.skChaseDot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes skChase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skChaseDot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skChaseDotBefore {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
