@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap");
@import "../home/variables.module.scss";

.fullContainer {
  margin: auto;
  min-height: 70vh;
  min-height: calc(100vh - 240px);
}

.contentContainer {
  margin: 0 60px;
  min-height: 70vh;
}

.splashHeader {
  text-align: center;
  font-size: 60px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-top: 80px;
}

@media screen and (max-width: $mobile) {
  .splashHeader {
    font-size: 40px;
    margin: 60px 20px 20px 20px;
  }
}

.splashImage {
  max-width: 800px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
}

.videoIntroContainer {
  margin: auto;
  max-width: 800px;
  display: flex;
  box-shadow: 10px 10px 10px 7px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.introVideo {
  width: 100%;
  height: 100%;
}

.splashSecondary {
  text-align: center;
  font-size: 20px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  margin: 10px;
  color: rgba(51, 51, 51, 0.6);
}

@media screen and (max-width: $mobile) {
  .splashSecondary {
    font-size: 14px;
  }
}

.splashDescription {
  text-align: center;
  font-size: 20px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  margin: 10px;
  color: rgba(51, 51, 51, 0.6);
}

.loginContainer {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 20px;
}

.loginContainerBottom {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 50px;
}

@media screen and (max-width: $mobile) {
  .loginContainerBottom {
    margin: 20px;
  }
}

.googleloginContainer {
  margin: 10px;
}

.buttonHelpText {
  font-family: "Lexend Deca", sans-serif;
  font-size: 14px;
  opacity: 50%;
  margin-bottom: 10px;
}

@media screen and (max-width: $mobile) {
  .buttonHelpText {
    font-size: 12px;
  }
}

.sectionHeader {
  font-size: 50px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 200px 20px 0 20px;
}

@media screen and (max-width: $mobile) {
  .sectionHeader {
    font-size: 26px;
    margin-top: 80px;
    margin: 80px 10px 0 10px;
  }
}

.numItem {
  margin: 50px 0px;
  font-size: 20px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
}

.greySection {
  padding: 0 80px;
}
@media screen and (max-width: $mobile) {
  .greySection {
    padding: 0 20px;
  }
}

.color1 {
  color: #2d7ff9;
}

.color2 {
  color: #20c933;
}

.color3 {
  color: #8b46ff;
}

.color4 {
  color: #f7c548;
}

.sideBySide {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 60px;
}

@media screen and (max-width: $mobile) {
  .sideBySide {
    margin: 20px 0px;
  }
}

.sectionImageContainer {
  width: 55%;
  min-width: 500px;
  border-radius: 5px;
  box-shadow: 10px 10px 10px 7px rgba(0, 0, 0, 0.1);
  margin-right: 4%;
}

.sectionImageContainerFull {
  width: 100%;
  border-radius: 5px;
  box-shadow: 10px 10px 10px 7px rgba(0, 0, 0, 0.1);
}

.sectionImage {
  width: 100%;
  height: 100%;
}

.sectionTextContainer {
  width: 41%;
  font-size: 20px;
  color: rgba(51, 51, 51, 0.6);
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
}

.flex3 {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  flex-basis: auto;
}

@media screen and (max-width: $mobile) {
  .flex3 {
    display: block;
  }
}

.futureBox {
  color: white;
  background: #8b46ff;
  padding: 30px;
  border-radius: 10px;
  margin: 20px;
  flex: 1 1 0px;
  border: 1px solid #f4f4f4;
  box-shadow: 10px 10px 10px 7px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: $mobile) {
  .futureBox {
    padding: 15px;
  }
}

.sectionIcon {
  font-size: 100px;
  margin: 10px auto 30px auto;
  display: flex;
  color: white;
}

@media screen and (max-width: $mobile) {
  .sectionIcon {
    font-size: 60px;
  }
}

.futureHeader {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.futureDescription {
  margin-bottom: 20px;
}

.bottomImageContainer {
  margin: auto;
  max-width: 1000px;
}

.bottomImage {
  width: 100%;
}
