@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import "./rounded-corners-mixin.module.scss";
@import "./variables.module.scss";
@import "./containers.module.scss";
@import "./buttons.module.scss";
@import "./progress.module.scss";

progress {
  transition: 1s;
}

.productHolder {
  float: right;
  margin-top: 20px;
}

.gameContainer {
  margin: auto;
  padding: 30px;
  font-family: "Press Start 2P", sans-serif;
  -webkit-font-smoothing: antialiased;
  max-width: 700px;
}

.leftMe {
  width: 70%;
}
.orangeBlob {
  height: 80px;
  width: 80px;
  background: orange;
  margin: 10px 0;
}

.rightEnemy {
  width: 70%;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
}

.blueBlob {
  height: 80px;
  width: 80px;
  background: blue;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  margin-top: 10px;
}

.attack {
  height: 80px;
  width: 80px;
  background: yellow;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  margin-top: 10px;
}

.defence {
  height: 80px;
  width: 80px;
  background: violet;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  margin-top: 10px;
}

.redBlob {
  height: 80px;
  width: 80px;
  background: red;
  margin-bottom: 10px;
}

.purpleBlob {
  height: 80px;
  width: 80px;
  background: red;
  margin-bottom: 10px;
  animation-name: blobChange;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes blobChange {
  from {
    background-color: red;
  }
  to {
    background-color: purple;
  }
}

.choices {
  margin-top: 20px;
}

.message {
  margin-top: 10px;
}
