@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;900&display=swap");
@import "../home/variables.module.scss";
.all {
  background: #fafafa;
}

.fullContainer {
  margin: auto;
  max-width: 800px;
  min-height: 70vh;
  min-height: calc(100vh - 240px);
}

.contentContainer {
  margin: 0 30px;
  min-height: 70vh;
}

.splashHeader {
  text-align: center;
  font-size: 80px;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  margin-top: 60px;
}

@media screen and (max-width: $mobile) {
  .splashHeader {
    font-size: 40px;
  }
}

.holdProductHunt {
  margin: auto;
  text-align: center;
  width: fit-content;
}
.sectionHeader {
  text-align: center;
  font-size: 50px;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  margin-top: 30px;
}

.bar {
  max-width: 500px;
  background: rgb(62, 79, 151);
  height: 3px;
  margin: auto;
  border-radius: 8px;
  margin-top: 30px;
}

.getItButton {
  margin: 40px auto 40px auto;
  // width: 350px;
  // height: 28px;
  width: fit-content;
  line-height: 28px;
  background: rgb(62, 79, 151);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-family: "Lexend Deca", sans-serif;
  text-align: center;
  transition: 0.25s;
  font-size: 18px;
  padding: 10px 30px;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    opacity: 0.85;
  }
}

@media screen and (max-width: $mobile) {
  .getItButton {
    width: 200px;
    font-size: 16px;
  }
}

.explainerText {
  font-size: 18px;
  font-family: "Lexend Deca", sans-serif;
  margin: 20px 30px 30px 30px;
  text-align: center;
  color: rgba(51, 51, 51, 0.6);
}

@media screen and (max-width: $mobile) {
  audio {
    width: 240px;
  }
}

@media screen and (max-width: $mobile) {
  .explainerText {
    font-size: 14px;
  }
}

.allSounds {
  // display: flex;
  // flex-wrap: wrap;
  margin: auto;
}

.soundContainer {
  text-align: center;
  // border: 2px solid rgb(62, 79, 151);
  max-width: 500px;
  margin: 80px auto;
  border-radius: 25px;
  padding: 20px;
  box-shadow: 10px 10px 10px 7px rgba(0, 0, 0, 0.1);
  background: white;
}

@media screen and (max-width: $mobile) {
  .soundContainer {
    margin: 40px auto;
    padding: 15px;
  }
}

.soundName {
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  font-size: 50px;
  margin-bottom: 5px;
}

@media screen and (max-width: $mobile) {
  .soundName {
    font-size: 30px;
  }
}

.bold {
  font-weight: 600;
}

.goodFor {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: $mobile) {
  .goodFor {
    font-size: 14px;
  }
}

.seeHow {
  // width: 200px;
  // height: 20px;
  width: fit-content;
  margin: 10px auto 20px auto;
  line-height: 20px;
  background: rgb(62, 79, 151);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-family: "Lexend Deca", sans-serif;
  text-align: center;
  transition: 0.25s;
  font-size: 16px;
  padding: 10px 30px;


  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    opacity: 0.85;
  }
}

@media screen and (max-width: $mobile) {
  .seeHow {
    margin: 10px auto 5px auto;
  }
}

.splashImageContainer {
  margin: 25px 0 0 0;
}

.splashImage {
  max-width: 800px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
}

.endImageContainer {
  margin: 10px 0 10px 0;
}

.endImage {
  max-width: 800px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
}

.splashDescription {
  text-align: center;
  font-size: 20px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  margin: 10px;
  color: rgba(51, 51, 51, 0.6);
}

@media screen and (max-width: $mobile) {
  .splashDescription {
    font-size: 16px;
  }
}

.question {
  text-align: left;
  font-size: 22px;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
  margin: 10px;
}

.answerText {
  text-align: left;
  font-size: 16px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  margin: 10px 10px 20px 10px;
}
