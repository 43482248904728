@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap");
@import "../home/variables.module.scss";

.all {
  background-color: #efefef;
}

.fullContainer {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 240px);
}

.contentContainer {
  margin: 0 20px;
  padding: 20px 0;
  box-sizing: content-box;
}

.header {
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.subHeader {
  font-size: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.statsRow {
  padding: 20px;
  background: rgb(246, 246, 240);
  display: flex;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.08),
    0 3px 6px 0 rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: $mobile) {
  .statsRow {
    flex-wrap: wrap;
  }
}

.statsContainer {
  box-shadow: inset 0 0 0 1px #e3e8ee;
  text-align: center;
  background: #cbf4c9;
  padding: 15px;
  width: 150px;
  font-size: 14px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  border-radius: 3px;
  margin-right: 20px;
  color: #0e6245;
}

@media screen and (max-width: $mobile) {
  .statsContainer {
    width: 220px;
    font-size: 16px;
    margin: 5px;
  }
}

.metric {
  font-family: "Roboto Slab", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: $mobile) {
  .metric {
    font-size: 26px;
  }
}

.postTitle {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.commentsParent {
  margin-top: 20px;
}

.commentRow {
  border-radius: 5px;
  background: rgb(246, 246, 240);
  margin: 20px 0;
  padding: 15px 20px;
  font-weight: 400;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.06),
    0 3px 6px 0 rgba(0, 0, 0, 0.06);
}

.author {
  margin-bottom: 10px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

// loading
.loadingHolder {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.emptyText {
  text-align: center;
  font-size: 18px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  padding: 10px 40px;
  margin-bottom: 15px;
}

.skChase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: skChase 2.5s infinite linear both;
}

.skChaseDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: skChaseDot 2s infinite ease-in-out both;
}

.skChaseDot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #f7c548;
  border-radius: 100%;
  animation: skChaseDotBefore 2s infinite ease-in-out both;
}

.skChaseDot:nth-child(1) {
  animation-delay: -1.1s;
}
.skChaseDot:nth-child(2) {
  animation-delay: -1s;
}
.skChaseDot:nth-child(3) {
  animation-delay: -0.9s;
}
.skChaseDot:nth-child(4) {
  animation-delay: -0.8s;
}
.skChaseDot:nth-child(5) {
  animation-delay: -0.7s;
}
.skChaseDot:nth-child(6) {
  animation-delay: -0.6s;
}
.skChaseDot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes skChase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skChaseDot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skChaseDotBefore {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
