@import "../home/variables.module.scss";

.fullContainer {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.paymentContainer {
  font-family: "Lexend Deca";
  margin: 20px;
  color: rgb(77, 77, 77);
}

.lineItem {
  margin: 10px;
}

.inputHolder {
  display: block;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  max-width: 501px;
  width: 256px;
  border-radius: 4px;
  background: white;
  margin: 15px 0;
}
.inputHolder:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: rgb(66, 71, 112);
}
.nameInput {
  display: block;
  max-width: 500px;
  width: 228px;
  padding: 10px 14px;
  font-size: 14px;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.nameInput::placeholder {
  color: #aab7c4;
}

.nameInput:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: rgb(66, 71, 112);
}

.highlight {
  color: $primary;
  font-weight: 600;
}

.upgradeButton {
  background: #272635;
  color: #ffffff;
  padding: 8px 20px;
  display: inline-block;
  border-radius: 5px;
  margin-right: 15px;
  transition: 0.2s;
  font-family: "Lexend Deca", sans-serif;
  line-height: 1.5;
  border-style: none;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.header {
  color: #000000;
  font-size: 30px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}
.subHeader {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 32px;
}

.lineItemContainer {
  display: flex;
  font-weight: 900;
  margin-bottom: 32px;
}

.how {
  padding: 20px;
  background-color: #ffeab6;
  border-radius: 5px;
  color: #4d4d4d;
  font-weight: 400;
}

.howHeader {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.total {
  margin-top: 15px;
  padding-right: 50px;
  font-size: 22px;
  font-weight: 600;
  color: #272635;
}

.helperText {
  margin-top: 8px;
  font-size: 12px;
}

.paymentLabel {
  font-size: 14px;
  font-weight: 600;
}
.checkoutForm {
  margin: 8px 0;
}

.buttonRow {
  display: flex;
  align-items: center;
}

.cancel {
  transition: 0.2s;
  font-size: 14px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.disclaimer {
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 250px;
}

.errorText {
  margin: 10px 0;
  font-weight: 600;
  color: #cc0000;
  font-size: 20px;
}
