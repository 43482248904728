@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import "../home/variables.module.scss";

$square: 1.32%;
$square-margin: 0.3%;

a {
  color: $primary;
}
// header infor
.infoContainer {
  color: rgba(13, 17, 43, 0.9);
}

.outerMargin {
  max-width: 1300px;
  padding: 50px 20px 10px 20px;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.infoHeader {
  font-size: 40px;
  font-family: "Roboto Slab", serif;
}



.roboto {
  font-family: "Roboto Slab", serif;
}

.sellFont {
  font-family: "Lexend Deca", sans-serif;
  color: white;
}

.infoLegend {
  font-size: 16px;
  font-family: "Lexend Deca", serif;
  text-transform: uppercase;
}
.infoDesc {
  margin: 15px 0;
  font-size: 14px;
  font-family: "Roboto Slab", serif;
  color: $primary;
  max-width: 500px;
}
.infoDescHide {
  text-align: center;
  margin: 15px 0;
  font-size: 14px;
  font-family: "Roboto Slab", serif;
  color: $primary;
  font-style: italic;
}

@media screen and (max-width: $mobile) {
  .outerMarginn {
    padding: 20px 20px 0 20px;
  }
  .infoHeader {
    font-size: 28px;
  }
  .infoLegend {
    font-size: 12px;
  }
  .infoDesc {
    font-size: 12px;
  }
  .infoDescHide {
    display: none;
  }
}
.legendRow {
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: rgba(13, 17, 43, 0.8);
}
.legendRowMilestone {
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: rgba(13, 17, 43, 0.8);
}
.passedLegend {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: rgb(177, 94, 108);
  font-size: 1px;
  transition: 0.2s;
  margin-right: 5px;
}
.notPassedLegend {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: rgb(179, 222, 193);
  font-size: 1px;
  transition: 0.2s;
  margin-right: 5px;
}
.milestoneLegend {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fed766;
  font-size: 1px;
  transition: 0.2s;
  margin-right: 5px;
}
@media screen and (max-width: $mobile) {
  .milestoneLegend {
    display: none;
  }
  .legendRowMilestone {
    display: none;
  }
}
// header infor END
.sticky {
  position: fixed;
  z-index: -1;
}
.stickyInfoContainer {
  min-width: 100px;
  padding: 0 20px;
  color: rgba(13, 17, 43, 0.9);
  font-size: 16px;
  font-family: "Lexend Deca", serif;
  text-transform: uppercase;
}

.blocksMobile {
  display: none;
}

@media screen and (max-width: $mobile) {
  .goHomeBlock {
    display: none;
  }

  .stickyInfoContainer {
    display: none;
  }
  .blocksNotMobile {
    display: none;
  }
  .blocksMobile {
    display: unset;
  }
}

.outerChart {
  max-width: 1300px;
  margin: auto;
}

.chartContainer {
  display: flex;
  margin: 20px 0 40px 0;
}
.cellContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin: 0 20px;
}
.passedCell {
  width: $square;
  padding-bottom: $square;
  margin: $square-margin;
  background: rgb(177, 94, 108);
  font-size: 1px;
  transition: 0.2s;
}
.passedCell:hover {
  transform: scale(1.4);
  box-shadow: 0px 0px 3px #fff;
  z-index: 2;
}
.notPassedCell {
  width: $square;
  padding-bottom: $square;
  background: rgb(179, 222, 193);
  margin: $square-margin;
  transition: 0.2s;
}
.notPassedCell:hover {
  transform: scale(1.4);
  box-shadow: 0px 0px 3px #fff;
  z-index: 2;
}

.milestoneCell {
  width: $square;
  padding-bottom: $square;
  background: #fed766;
  margin: $square-margin;
  transition: 0.2s;
}
.milestoneCell:hover {
  transform: scale(1.4);
  box-shadow: 0px 0px 3px #fff;
  z-index: 2;
  cursor: pointer;
}

.quoteBackground {
  background: #f9f9f9;
  z-index: 1;
  display: flex;
}

.goHomeBanner {
  background: #3b82F6;
  z-index: 1;
  display: flex;
}

.bar {
  height: 2px;
  background: $primary;
  margin: 50px 0;
  border-radius: 5px;
}
.quote {
  font-family: "Roboto Slab", serif;
  margin: 5% 15%;
  font-size: 30px;
}

.twitterText {
 margin-right: 10px;
}

.twitterHold {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #3b82F6;
}

.sellLine {
  text-align: center;
  font-size: 24px;
  color: $primary;
  font-weight: 600;
}
@media screen and (max-width: $mobile) {
  .quote {
    font-size: 22px;
  }
}
.popback {
  width: 100vw;
  background: #fff;
  z-index: 1;
}

.glanceContainer {
  z-index: 4;
  display: flex;
}
.glanceChart {
  width: 50%;
  min-width: 400px;
  max-width: 500px;
}

.glanceInfoContainer {
  max-width: 70%;
}
@media screen and (max-width: $mobile) {
  .glanceChart {
    display: none;
  }
  .glanceInfoContainer {
    max-width: 100%;
  }
}
.sectionContainer {
  padding: 50px 20px;
  color: rgba(13, 17, 43, 0.9);
}
.infoPreHeader {
  text-transform: uppercase;
  font-family: "Lexend Deca", serif;
  font-size: 12px;
  color: $primary;
}
.infoRow {
  display: flex;
  align-items: center;
  font-family: "Lexend Deca", serif;
  font-size: 20px;
  margin: 30px 20px;
  color: rgba(13, 17, 43, 0.9);
}
@media screen and (max-width: $mobile) {
  .infoRow {
    margin: 20px 0px;

    font-size: 14px;
  }
}
.hourglassIcon {
  font-size: 25px;
  color: grey;
}
.iconContainer {
  display: flex;
  align-items: center;
  min-width: 25px;
  margin-right: 10px;
}
.heroBackground {
  display: block;
  background: #f9f9f9;
  z-index: 1;
  padding: 50px 20px;
}
.heroCardContainer {
  display: flex;
  flex-wrap: wrap;
}

.heroCard {
  display: flex;
  width: 45%;
  background: #fff;
  margin: 30px 10px;
  border-radius: 5px;
  align-items: center;
}
@media screen and (max-width: $mobile) {
  .heroBackground {
    display: block;
    background: #f9f9f9;
    z-index: 1;
    padding: 50px 20px;
  }
  .heroCardContainer {
    display: block;
    flex-wrap: unset;
  }

  .heroCard {
    display: flex;
    width: 100%;
    background: #fff;
    margin: 30px 10px;
    border-radius: 5px;
    align-items: center;
  }
}
.heroImageContainer {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 20px;
  //height: 100%;
}
.heroImage {
  width: 100%;
  border-radius: 5px;
}
.heroText {
  width: 50%;
  margin: 10px;
}
.heroName {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  font-family: "Roboto Slab";
}
.heroAge {
  font-size: 12px;
  font-family: "Lexend Deca";
}
.heroAccomplishmentLabel {
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  font-family: "Roboto Slab";
}
.heroDesc {
  font-size: 12px;
  font-family: "Lexend Deca";
  margin-bottom: 15px;
}
.paragraph {
  max-width: 1000px;
}

.bigButton {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  font-family: "Lexend Deca", sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  text-align: center;
  padding: 20px 26px;
  min-width: 210px;
  background: $primary;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  width: 350px;
  margin: 40px auto;
  font-size: 19px;
}
.bigButton:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35);
}
.bigButton:active {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35),
    inset 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 800px) {
  .bigButton {
    padding: auto;
    width: 150px;
  }
}
