@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import "./variables.module.scss";

.headerStrip {
  background: $mustard;
  height: 80px;
  display: flex;
  align-items: center;
}

.progress {
  margin: auto;
  display: flex;
  font-weight: 600;
  align-items: center;
}

.spacer {
  height: 1px;
  background-color: #f5f5f5;
  width: 60px;
  vertical-align: middle;
}

.progressItem {
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.currentNumber {
  background: #007aff;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.upcomingNumber {
  background: #f4f4f4;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.containerBorder {
  // font-family: "Poppins", sans-serif;
  margin: 30px;
  min-height: calc(100vh - 230px);
}

.contentContainer {
  max-width: 600px;
  margin: auto;
}

.pageHeader {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 40px;
}

.questionContainer {
  margin-bottom: 60px;
}

.questionLabel {
  font-size: 1rem;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 10px;
}

.questionDetails {
  font-size: 14px;
  max-width: 600px;
  color: #3c3c3c;
  margin-bottom: 10px;
}

.invalid {
  color: #cc0000;
  font-size: 14px;
  font-weight: 600;
  max-width: 600px;
  margin-bottom: 10px;
}

.simpleInput {
  display: block;
  width: 100%;
  max-width: 400px;
  font-family: inherit;
  color: rgb(0, 58, 81);
  padding: 0px 0px 0px;
  border: none;
  outline: none;
  border-radius: 0px;
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  transition: box-shadow 0.1s ease-out 0s;
  box-shadow: rgba(0, 58, 81, 0.3) 0px 1px;
  margin-bottom: 20px;
  &:focus {
    box-shadow: rgb(0, 58, 81) 0px 2px;
  }
}

.simpleArea {
  width: 100%;
  margin-top: 5px;
  display: block;
  max-width: 400px;
  height: 80px;
  font-family: inherit;
  color: rgb(0, 58, 81);
  padding: 4px;
  font-size: 14px;
  border-color: rgba(0, 58, 81, 0.3);
  border-radius: 5px;
  transition: 0.1s;
  &:focus {
    outline: none;
    border-color: rgba(0, 58, 81, 0.5);
  }
}

.simpleInputTight {
  font-size: 18px;
  max-width: 600px;
}

.rate {
  max-width: 200px;
}

.multiChoiceContainer {
  max-width: 400px;
}

.ulPretty {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0px;
}

.ulPretty > li {
  display: flex;
  flex-basis: calc(50% - 40px);
  flex-direction: column;
  margin: 5px;
}

.liPretty {
  position: relative;
  display: flex;
  border-radius: 4px;
  background-color: rgba(60, 60, 60, 0.1);
  color: rgb(60, 60, 60);
  max-width: 100%;
  min-width: 75px;
  min-height: 30px;
  line-height: 30px;
  outline: 0px;
  padding: 4px 4px 4px 20px;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  width: 50%;
  cursor: pointer;
  opacity: 1;
  &:hover {
    background-color: rgba(60, 60, 60, 0.2);
  }
  &:active {
    background-color: rgba(60, 60, 60, 0.3);
  }
}

.selectedVerb {
  background-color: $mustard;
  font-weight: 600;
  &:hover {
    background-color: $mustard;
    opacity: 0.9;
  }
}

.bottomContainer {
  max-width: 600px;
}

.continue {
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  text-align: center;
  background: #007aff;
  font-weight: 400;
  font-size: 14px;
  transition: 0.2s;
  color: white;
  font-weight: 600;
  float: right;
  &:hover {
    transform: translateY(-1px);
    opacity: 0.85;
    cursor: pointer;
  }
}

.stripeButton {
  background: #007aff;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  width: fit-content;
  padding: 5px 15px;
  margin: 10px 0;
  font-size: 14px;
  margin-top: 50px;
  &:hover {
    transform: translateY(-1px);
    opacity: 0.85;
    cursor: pointer;
  }
}

.stripeBadge {
  margin-bottom: 30px;
}

.paymentsHeader {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.paymentsSubHeader {
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .container {
    margin: 20px;
    min-height: calc(100vh - 200px);
  }

  .simpleInput {
    width: 100%;
  }

  .simpleArea {
    width: calc(100% - 5px);
  }
}

.exit {
  font-weight: 600;
  transition: 0.2s;
  width: fit-content;
  &:hover {
    text-decoration: underline;
    opacity: 0.7;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .spacer {
    display: none;
  }
  .stepName {
    display: none;
  }
}
