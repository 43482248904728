@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import "./variables.module.scss";

a {
  text-decoration: none;
}

.navbarContainer {
  display: flex;
  height: 65px;
  line-height: 45px;
  padding: 10px 30px;
  vertical-align: middle;
  max-width: 1300px;
  margin: auto;
}

.homeLogo {
  color: #3a3335;
  font-size: 30px;
  font-family: "Roboto Slab", "Lexend Deca";
  font-weight: 700;
  transition: 0.2s;
}

.byFailflow{
  font-size: 20px;
}

.homeLogo:hover {
  color: $primary;
  cursor: pointer;
}

.navButtonsContainer {
  font-size: 14px;
  display: inline-block;
  padding-left: 40px;
  line-height: 45px;
  color: rgba(13, 17, 43, 0.8);
  font-family: "Lexend Deca", sans-serif;
  margin-left: auto;
}

.signInButton {
  font-size: 13px;
  margin-left: auto;
  text-align: center;
  color: hsla(0, 0%, 100%, 0.85);
  border-radius: 4px;
  background: $primary;
  font-family: "Lexend Deca", sans-serif;
  padding: 0 20px;
  text-decoration: none;
}

.signInButton:hover {
  background: $primary-hover;
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.95);
}

.navItem a {
  color: rgba(13, 17, 43, 0.8);
  &:hover {
    color: $primary;
  }
}

.navItem {
  transition: 0.1s;
  margin-left: 30px;
  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

.mobileMenuIconFlex {
  display: none;
}

.drawer {
  position: fixed;
  top: 65px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
  z-index: 1;
}

.drawerItem {
  padding: 20px 10px;
  font-weight: 600;
}

.spacer {
  height: 1px;
  width: 100%;
  background: rgb(218, 218, 218);
}

.drawerContentContainer {
  padding: 30px;
  font-size: 20px;
}

.drawerContentContainer > a:link {
  text-decoration: none;
  color: inherit;
}
.drawerContentContainer > a:visited {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: $mobile) {
  .mobileDissapear {
    display: none;
  }

  .mobileMenuIconFlex {
    margin-left: auto;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  .menuIcon {
    font-size: 30px;
  }
}
