@import "./variables.module.scss";

.contentContainer {
  margin: 100px 50px;
  box-sizing: border-box;
}

.flexSplash {
  margin-bottom: 150px;
  text-align: center;
}

.lefty {
  margin-bottom: 40px;
  margin-top: 60px;
}

.splashHeader {
  font-size: 74px;
  line-height: 96%;
  font-weight: 700;
  margin-bottom: 15px;
}

.splashDescription {
  margin-top: 20px;
  margin-bottom: 20px;
}

.imgContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 0 auto;
}

.splashImg {
  width: 100%;
  border-radius: 10px;
  margin: auto;
}

.dashboardButton {
  background: black;
  border-radius: 5px;
  color: white;
  width: fit-content;
  margin: auto;
  padding: 15px 25px;
  font-weight: 600;
  font-size: 18px;
  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transform: translateY(-1px);
  }
}

.demoContainer {
  background-color: #fcf8e8;
  width: 100%;
  padding: 30px;
  margin: auto;
  max-width: 1200px;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
}

.demoHeader {
  font-size: 40px;
  font-weight: 600;
  margin: 15px 60px;
}

.demoSubHeader {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 50px;
}

.demoImageContainer {
  width: 100%;
}

.demoImage {
  width: 100%;
  max-width: 800px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
}

.divider {
  width: 100%;
  max-width: 1300px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  margin: 90px auto;
}

.empowerSection {
  text-align: center;
}
.sectionHeader {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 15px;
}

.sectionSubHeader {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 50px;
}

.placeholder {
  background-color: bisque;
  height: 800px;
}

@media screen and (max-width: 800px) {
  .splashHeader {
    font-size: 50px;
  }

  .flexSplash {
    margin-bottom: 50px;
  }

  .contentContainer {
    margin: 30px 20px 20px 20px;
  }

  .demoHeader {
    font-size: 28px;
    margin: 0 0 10px 0;
  }

  .demoSubHeader {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
