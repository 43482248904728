@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import "./variables.module.scss";

.container {
  font-family: "Poppins", sans-serif;
  margin: 60px;
  min-height: calc(100vh - 300px);
}

.contentContainer {
  max-width: 800px;
  margin: auto;
}

.headerStrip {
  height: 100px;
  background: $mustard;
  display: flex;
  align-items: center;
  justify-content: center;
}

.makeYourOwn {
  margin-left: auto;
  margin-right: 20px;
  width: 150px;
  background: #007aff;
  height: 50px;
  text-align: center;
  padding: 5px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: translateY(-1px);
    cursor: pointer;
    opacity: 0.9;
  }
}

.splashHeader {
  width: fit-content;
  font-size: 50px;
  line-height: 96%;
  font-weight: 700;
  padding: 5px;
  margin-bottom: 20px;
}

.bioInfo {
  font-size: 20px;
  max-width: 600px;
  margin-bottom: 40px;
}

.availableTo {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}

.helpWith {
  font-size: 20px;
  margin-bottom: 5px;
  // font-weight: 600;
}

.helpList {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.scheduleTime {
  padding: 8px 15px;
  background: #007aff;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: fit-content;
  border-radius: 5px;
  transition: 0.2s;
  &:hover {
    transform: translateY(-1px);
    cursor: pointer;
    opacity: 0.9;
  }
}

.scheduleContainer {
  background: #f2f2f2;
  width: 100%;
  max-width: 600px;
  padding: 25px;
}

.success {
  margin: 30px 0;
  color: #007aff;
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .container {
    margin: 15px;
    min-height: calc(100vh - 200px);
  }

  .simpleInput {
    width: 100%;
  }
}
