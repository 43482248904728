.nesContainer {
  position: relative;
  padding: 1.5rem 2rem;
  border-color: black;
  border-style: solid;
  border-width: 4px;

  > :last-child {
    margin-bottom: 0;
  }

  &.isCentered {
    text-align: center;
  }

  &.isRight {
    text-align: right;
  }

  &.withTitle {
    > .title {
      display: table;
      padding: 0 0.5rem;
      margin: -1.8rem 0 1rem;
      font-size: 1rem;
      background-color: $background-color;
    }

    &.isCentered {
      > .title {
        margin: -2rem auto 1rem;
      }
    }

    &.isRight {
      > .title {
        margin: -2rem 0 1rem auto;
      }
    }
  }

  &.isDark {
    position: relative;
    margin: $border-size;
    color: $background-color;
    background-color: $base-color;
    border-color: white;

    &::after {
      position: absolute;
      top: -$border-size * 1.8;
      right: -$border-size * 1.8;
      bottom: -$border-size * 1.8;
      left: -$border-size * 1.8;
      z-index: -1;
      content: "";
      background-color: $base-color;
    }

    &.withTitle {
      > .title {
        color: $background-color;
        background-color: $base-color;
      }
    }
  }

  &.isRounded {
    @include rounded-corners();

    padding: 1rem 1.5rem;
    margin: $border-size;

    &.withTitle {
      > .title {
        margin-top: -1.5rem;
      }

      &.isCentered {
        > .title {
          margin: -1.5rem auto 1rem;
        }
      }

      &.isRight {
        > .title {
          margin: -1.5rem 0 1rem auto;
        }
      }
    }

    &.isDark {
      @include rounded-corners(true);
      &::after {
        content: none;
      }

      &.withTitle {
        > .title {
          margin-top: -1.3rem;
        }

        &.isCentered {
          > .title {
            margin: -1.3rem auto 1rem;
          }
        }

        &.isRight {
          > .title {
            margin: -1.3rem 0 1rem auto;
          }
        }
      }
    }
  }
}
